import React from "react"
import { Link } from "gatsby"

export default props => {
  return (
    <ul>
      {props.data.edges.map(({ node }) => (
      <li>
      <Link to={node.Town.toLowerCase()}>{node.Town.replace(/-/g,' ')}</Link>
       </li>
        ))}
          </ul>
  )
}