import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Breadcrumb from "../components/breadcrumb"
import SEO from "../components/seo"
import ListTowns from "../components/list-towns-inregion"


export default function Destination({ data, pageContext }) {
  const region = data.locationdata.nodes[0]
  const name = region.Region
  const seotitle = `${name} Staycations`
  let crumbdata = [ ['Destinations','destinations'],`${name}`]
  let heroimage = ''
  let html = ''
  if (data.markdowndata){
    heroimage = data.markdowndata.keyimage.childImageSharp.fluid
    html = data.markdowndata.html
  } else {
    heroimage = region.keyimage.childImageSharp.fluid
  }

return (
  <Layout>
      <SEO title={seotitle} description="Never been beyond your house and the airport? Don't worry we've got you sorted." />
      <Breadcrumb crumbs={ crumbdata } />
      <Img fluid={ heroimage } />
      <h1>Staycation guide to {name}</h1>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
      <div>
        <h2>Destinations in {name}</h2>
      <ListTowns data= {data.childrenlist} />
      </div>
  </Layout>
)
}


export const query = graphql`
  query($slug: String!, $name: String!) {
    locationdata: allTownsCsv(limit: 1, sort: {fields: [fields___popularitynumber], order: DESC}, filter: {Region: {eq: $name}}) {
      nodes {
        Region
      keyimage {
        childImageSharp {
          fluid(maxWidth: 925, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }

  childrenlist: allTownsCsv(sort: {fields: [fields___popularitynumber], order: DESC}, filter: {Region: {eq: $name}}) {
    edges{
      node {
      Town
      }
    }
}

  markdowndata: markdownRemark(frontmatter: {type: {eq: "destination"}}, fields: { slug: { eq: $slug }}) {
    html
    frontmatter {
      title
    }
    keyimage {
      childImageSharp {
        fluid(maxWidth: 925, maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

  }
`